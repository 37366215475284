import React, { useState } from 'react';
import { Layout, Menu, Button, Modal, Form, Input, Select, message } from 'antd';
import { useNavigate } from "react-router-dom";
import axios from 'axios'; // for making API requests
//import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebaseConfig";

const { Header, Content, Footer } = Layout;
const { Option } = Select;

export default function Dashboard() {
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [form] = Form.useForm();
  const [projectType, setProjectType] = useState('');
  const navigate = useNavigate();


  const handleLogoutClick = () => {
    setLogoutVisible(true);
  };

const confirmLogout = async () => {
  try {
    await signOut(auth);
    message.success('Successfully logged out!');
    setLogoutVisible(false);
    navigate("/");
  } catch (error) {
    console.error("Error during logout:", error);
    message.error('Failed to log out. Please try again.');
  }
};

  const cancelLogout = () => {
    setLogoutVisible(false);
  };

  const handleProjectTypeChange = (value) => {
    setProjectType(value);
  };

  const handleSubmit = async (values) => {
    // Call backend API to clone the repository and deploy the project
    try {
      const response = await axios.post('/api/deploy', values);
      message.success('Deployment started!');
    } catch (error) {
      message.error('Deployment failed!');
    }
  };

  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">Dashboard</Menu.Item>
          <Menu.Item key="2">
            <Button type="primary" onClick={handleLogoutClick}>
              Logout
            </Button>
          </Menu.Item>
        </Menu>
      </Header>

      <Content style={{ padding: '50px', minHeight: '80vh' }}>
        <div className="site-layout-content">
          <h2>Welcome to the Dashboard</h2>

          <Button type="primary" onClick={() => setFormVisible(true)}>
            Clone and Deploy Git Repository
          </Button>

          {/* Form for Cloning and Deploying a Git Repository */}
          <Modal
            title="Clone and Deploy Git Repository"
            visible={formVisible}
            onCancel={() => setFormVisible(false)}
            onOk={() => form.submit()}
            okText="Deploy"
            cancelText="Cancel"
          >
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                label="Git Repository URL"
                name="gitUrl"
                rules={[{ required: true, message: 'Please enter the Git repository URL' }]}
              >
                <Input placeholder="https://github.com/user/repo.git" />
              </Form.Item>

              <Form.Item
                label="Project Type"
                name="projectType"
                rules={[{ required: true, message: 'Please select the project type' }]}
              >
                <Select onChange={handleProjectTypeChange}>
                  <Option value="java">Java</Option>
                  <Option value="react">ReactJS</Option>
                  <Option value="next">NextJS</Option>
                </Select>
              </Form.Item>

              {projectType === 'java' && (
                <Form.Item
                  label="Create Database"
                  name="createDatabase"
                  valuePropName="checked"
                >
                  <Input type="checkbox" />
                </Form.Item>
              )}
            </Form>
          </Modal>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>Dashboard ©2024 Created by You</Footer>

      {/* Modal for Logout Confirmation */}
      <Modal
        title="Confirm Logout"
        visible={logoutVisible}
        onOk={confirmLogout}
        onCancel={cancelLogout}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </Layout>
  );
}


