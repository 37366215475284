// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyADuQhr8qd0N-qbxHZrzmtQA95Q3EsKVM4",
  authDomain: "lht-automobile.firebaseapp.com",
  projectId: "lht-automobile",
  storageBucket: "lht-automobile.appspot.com",
  messagingSenderId: "101960830145",
  appId: "1:101960830145:web:09e561f2ee5e275bb8d840"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
